import React from "react";
import ReactDOM from "react-dom";
import "./Loader.scss";
import { CircularProgress } from "@mui/material";

const Loader = () => {
  return ReactDOM.createPortal(
    <div className="wrapper">
      <div className="loader">
        <CircularProgress color="primary"/>
      </div>
    </div>,
    document.getElementById("loader")
  );
};

export const SpinnerImg = () => {
  return (
    <div className="--center-all">
      <CircularProgress color="primary"/>
    </div>
  );
};

export default Loader;
