import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";
import { selectIsLoggedIn } from "../../../redux/features/auth/authSlice";
import { getDonation } from "../../../redux/features/donations/donationsSlice";
import Card from "../../card/Card";
import { SpinnerImg } from "../../loader/Loader";
import { Button } from "@mui/material";
import "./DonationDetail.scss";
import DOMPurify from "dompurify";
import moment from "moment";
import { BiEdit, BiTrash, BiArrowFromBottom } from "react-icons/bi";
import { FaRegHandPaper } from "react-icons/fa";
import { BsGift } from "react-icons/bs";
import { selectAdmin } from "../../../redux/features/auth/authSlice";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  deleteDonation,
  getDonations,
} from "../../../redux/features/donations/donationsSlice";


const DonationDetail = () => {
  useRedirectLoggedOutUser("/login");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  
const admin = useSelector(selectAdmin);

  const { donation, isLoading, isError, message } = useSelector(
    (state) => state.donations
  );



  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getDonation(id));
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);


  const delProduct = async (id) => {
    console.log(id);
    await dispatch(deleteDonation(id));
    await dispatch(getDonations());
    
    navigate("/");
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Item",
      message: "Are you sure you want to delete this item.",
      buttons: [
        {
          label: "Delete",
          onClick: () => delProduct(id),
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };


  return (
    <div className="donation-detail">
      <Card cardClass="card">
        {isLoading && <SpinnerImg />}
        {donation && (
          <div className="detail">
      
        
      <h2>{donation?.item_title} for {donation?.program}</h2>
          <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(donation?.item_description),
              }}
            ></div>
          <h4>Organizer Contact Info: <p style={{whiteSpace:"pre-wrap", marginTop:10}}>{donation?.organizerInfo}</p></h4>

          <h4>Donation Details:</h4>
          <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(donation?.donation_description),
              }}
            ></div>

            <hr />
            <code className="--color-dark">
              Donated on: {moment(donation.donation_date).format('MMM DD YYYY')} ({moment(donation.donation_date).fromNow()})
            </code>
            <code className="--color-dark">
              Created on: {moment(donation.createdAt).format('MMM DD YYYY')} ({moment(donation.createdAt).fromNow()})
            </code>
            <code className="--color-dark">
              Last Updated: {moment(donation.createdAt).format('MMM DD YYYY')} ({moment(donation.createdAt).fromNow()})
            </code>

            
            {admin ? (
<div>

<Button component={Link} to={`/product-detail/${donation?.item_id}`} variant="contained" color="secondary" disableElevation>
             <BiArrowFromBottom/> <h4>Open Item</h4>
            </Button>

<Button component={Link} to={`/edit-donation/${id}`} variant="contained" color="secondary" disableElevation>
             <BiEdit/> <h4>Edit</h4>
            </Button>

            <Button onClick={() => confirmDelete(id)} variant="contained" color="secondary" disableElevation>
             <BiTrash/> <h4>Delete</h4>
            </Button>
                          

  </div>
            ) : (null)}
           


          
          </div>
        )}
      </Card>
    </div>
  );
};

export default DonationDetail;
