import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Card from "../../card/Card";
import {TextField,Select,MenuItem,Radio,RadioGroup,FormControlLabel, Tooltip, IconButton} from '@mui/material'
import {BsInfoCircle} from 'react-icons/bs'
import { stringToBoolean } from "../../../utils/helperFunctions";
import DatePicker from "react-datepicker";
import "./DonationForm.scss";
import DOMPurify from "dompurify";
import "react-datepicker/dist/react-datepicker.css";

const DonationForm = ({
  product,
  description,
  setDescription,
  date,
  setDate,
  saveDonation,
}) => {
  return (
    <div className="add-donation">
      <Card cardClass={"card"}>
        <form onSubmit={saveDonation} className='donation-form'>


          <label>{product?.title} for {product?.program}</label>
          <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(product?.description),
              }}
            ></div>
          <h4>Organizer Contact Info: <p style={{whiteSpace:"pre-wrap", marginTop:10}}>{product?.organizerInfo}</p></h4>

      
          <ToolTipFormLabel labelText="Donation Description:" toolTip="A detailed description for the donation. Mainly who it was donated by and any other relevant details for the donation."/>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            modules={DonationForm.modules}
            formats={DonationForm.formats}
          />

      <ToolTipFormLabel labelText="Donated Date:" toolTip="The date in which the donation was fulfilled."/>
        <div style={{ border: "1px #c5c5c5 solid",borderRadius:"4px"}}>
        <DatePicker selected={date} onChange={(newDate) => setDate(newDate)} />
        </div>
          


          <div className="--my">
            <button type="submit" className="--btn --btn-primary">
              Save Donation
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

DonationForm.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ align: [] }],
    [{ color: ["#000000","blue","red","green"] }, { background: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["clean"],
  ],
};
DonationForm.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "color",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "video",
  "image",
  "code-block",
  "align",
];

function ToolTipFormLabel({ labelText, toolTip }) {
  return (
    <label>{labelText} <Tooltip title={<p>{toolTip}</p>}><IconButton><BsInfoCircle size={15} color="#000" /></IconButton></Tooltip></label>
  );
}

export default DonationForm;
