import React from "react";

const Footer = () => {
  return (
    <div className="--flex-center --py2">
      {/* <p>All Rights Reserved. &copy; 2022</p> */}
    </div>
  );
};

export default Footer;
