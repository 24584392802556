import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import ProductForm from "../../components/product/productForm/ProductForm";
import { selectName } from "../../redux/features/auth/authSlice";
import { stringToBoolean } from "../../utils/helperFunctions";
import {
  createProduct,
  selectIsLoading,
} from "../../redux/features/product/productSlice";

const initialState = {
  title: "",
  program: "Other",
  quantity: 0,
  isGood: true,
  isClaimed:false,
  isArchived:false,
  isOngoing:false,
  urgency:1
};

const AddProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, setProduct] = useState(initialState);
  const [date, setDate] = useState(new Date());
  const [productImage, setProductImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [description, setDescription] = useState("");
  const name = useSelector(selectName);

  const isLoading = useSelector(selectIsLoading);

  const { title, isGood, isClaimed, program, isArchived, needByDate, isOngoing, quantity, urgency, organizerInfo} = product;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
    console.log(product)
  };


  const handleImageChange = (e) => {
    setProductImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const saveProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user", name);
    formData.append("title", title);
    formData.append("isGood",stringToBoolean(isGood));
    formData.append("isClaimed", stringToBoolean(isClaimed));
    formData.append("isArchived", stringToBoolean(isArchived));
    formData.append("program", program);
    formData.append("quantity", Number(quantity));
    formData.append("needByDate", date);
    formData.append("isOngoing", stringToBoolean(isOngoing));
    formData.append("urgency", Number(urgency));
    formData.append("description", description);
    formData.append("organizerInfo", organizerInfo);
    formData.append("image", productImage);


    const success = await dispatch(createProduct(formData))
    if (success.type == "products/create/fulfilled"){
    navigate("/dashboard");
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      <h3 className="--mt">Add New Item</h3>
      <ProductForm
        product={product}
        productImage={productImage}
        imagePreview={imagePreview}
        description={description}
        setDescription={setDescription}
        date={date}
        setDate={setDate}
        handleInputChange={handleInputChange}
        handleImageChange={handleImageChange}
        saveProduct={saveProduct}
      />
    </div>
  );
};

export default AddProduct;
