import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Dashboard from "./pages/dashboard/Dashboard";
import Archive from "./pages/archive/Archive";
import Donations from "./pages/donations/Donations";
import Sidebar from "./components/sidebar/Sidebar";
import Layout from "./components/layout/Layout";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { getLoginStatus } from "./services/authService";
import { SET_LOGIN } from "./redux/features/auth/authSlice";
import AddProduct from "./pages/addProduct/AddProduct";
import ProductDetail from "./components/product/productDetail/ProductDetail";
import EditProduct from "./pages/editProduct/EditProduct";
import AddDonation from "./pages/addDonation/AddDonation";
import DonationDetail from "./components/donations/donationDetail/DonationDetail";
import EditDonation from "./pages/editDonation/EditDonation";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/profile/EditProfile";
import Contact from "./pages/contact/Contact";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import theme from './utils/theme'

import { useSelector } from "react-redux";
import { selectAdmin } from "./redux/features/auth/authSlice";


const main_theme = createTheme(theme);

axios.defaults.withCredentials = true;



function App() {
  const dispatch = useDispatch();
  const admin = useSelector(selectAdmin);
  useEffect(() => {
    async function loginStatus() {
      const status = await getLoginStatus();
      dispatch(SET_LOGIN(status));
    }
    loginStatus();
  }, [dispatch]);

  return (
    <ThemeProvider theme={main_theme}>
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/resetpassword/:resetToken" element={<Reset />} />

        <Route
          path="/dashboard"
          element={
            <Sidebar>
              <Layout>
                <Dashboard />
              </Layout>
            </Sidebar>
          }
        />
         <Route
          path="/donations"
          element={
            admin ? 
            <Sidebar>
              <Layout>
                <Donations  />
              </Layout>
            </Sidebar>
            :
            <Navigate to="/dashboard"/>
          }
        />
         <Route
          path="/archive"
          element={
            admin ? 
            <Sidebar>
              <Layout>
                <Archive  />
              </Layout>
            </Sidebar>
            :
            <Navigate to="/dashboard"/>
          }
        />
        <Route
          path="/add-product"
          element={
            admin ? 
            <Sidebar>
              <Layout>
                <AddProduct  />
              </Layout>
            </Sidebar>
            :
            <Navigate to="/dashboard"/>
          }
        />
        <Route
          path="/product-detail/:id"
          element={
            <Sidebar>
              <Layout>
                <ProductDetail />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/edit-product/:id"
          element={
            admin ? 
            <Sidebar>
              <Layout>
                <EditProduct  />
              </Layout>
            </Sidebar>
            :
            <Navigate to="/dashboard"/>
          }
        />
         <Route
          path="/edit-product/:id/archive"
          element={
            admin ? 
            <Sidebar>
              <Layout>
              <EditProduct archive={true}/>
              </Layout>
            </Sidebar>
            :
            <Navigate to="/dashboard"/>
          }
        />
         <Route
          path="/edit-product/:id/claimed"
          element={
            admin ? 
            <Sidebar>
              <Layout>
              <EditProduct claimed={true}/>
              </Layout>
            </Sidebar>
            :
            <Navigate to="/dashboard"/>
          }
        />
             <Route
          path="/add-donation/:id"
          element={
            admin ? 
            <Sidebar>
              <Layout>
              <AddDonation />
              </Layout>
            </Sidebar>
            :
            <Navigate to="/dashboard"/>
          }
        />
        <Route
          path="/donation-detail/:id"
          element={
            admin ? 
            <Sidebar>
              <Layout>
              <DonationDetail />
              </Layout>
            </Sidebar>
            :
            <Navigate to="/dashboard"/>
          }
        />
        <Route
          path="/edit-donation/:id"
          element={
            admin ? 
            <Sidebar>
              <Layout>
              <EditDonation />
              </Layout>
            </Sidebar>
            :
            <Navigate to="/dashboard"/>
          }
        />
        <Route
          path="/profile"
          element={
            <Sidebar>
              <Layout>
                <Profile />
              </Layout>
            </Sidebar>
          }
        />
         <Route
          path="/register"
          element={
            admin ? 
            <Sidebar>
              <Layout>
                <Register  />
              </Layout>
            </Sidebar>
            :
            <Navigate to="/dashboard"/>
          }
        />
        <Route
          path="/edit-profile"
          element={
            <Sidebar>
              <Layout>
                <EditProfile />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/help"
          element={
            <Sidebar>
              <Layout>
                <Contact />
              </Layout>
            </Sidebar>
          }
        />
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
