import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";
import { selectIsLoggedIn } from "../../../redux/features/auth/authSlice";
import { getProduct } from "../../../redux/features/product/productSlice";
import Card from "../../card/Card";
import { SpinnerImg } from "../../loader/Loader";
import { Button } from "@mui/material";
import "./ProductDetail.scss";
import DOMPurify from "dompurify";
import moment from "moment";
import { BiEdit, BiTrash, BiArchive } from "react-icons/bi";
import { FaRegHandPaper } from "react-icons/fa";
import { BsGift } from "react-icons/bs";
import ClaimButton from "./ClaimButton";
import { selectAdmin } from "../../../redux/features/auth/authSlice";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  deleteProduct,
  getProducts,
} from "../../../redux/features/product/productSlice";


const ProductDetail = () => {
  useRedirectLoggedOutUser("/login");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  
const admin = useSelector(selectAdmin);

  const { product, isLoading, isError, message } = useSelector(
    (state) => state.product
  );

  const claimedStatus = (isClaimed) => {
    if (isClaimed) {
      return <span className="--color-success">Claimed</span>;
    }
    return <span className="--color-danger">Unclaimed</span>;
  };

  const goodStatus = (isGood) => {
    if (isGood) {
      return <span className="">Good</span>;
    }
    return <span className="">Service</span>;
  };

  const urgencyStatus = (urgency) => {
    if (urgency == 3) {
      return <span className="--color-high">High</span>;
    } else if (urgency == 2) {
      return <span className="--color-medium">Medium</span>;
    } else {
      return <span className="--color-low">Low</span>;
    }
  };

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getProduct(id));
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);


  const delProduct = async (id,archived) => {
    console.log(id);
    await dispatch(deleteProduct(id));
    await dispatch(getProducts());
    if (archived){navigate("/archive");}
    else{navigate("/dashboard");}
    
  };

  const confirmDelete = (id,archived) => {
    confirmAlert({
      title: "Delete Item",
      message: "Are you sure you want to delete this item.",
      buttons: [
        {
          label: "Delete",
          onClick: () => delProduct(id,archived),
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };




  return (
    <div className="product-detail">
      <Card cardClass="card">
        {isLoading && <SpinnerImg />}
        {product && (
          <div className="detail">
            <Card cardClass="group">
              
              {product?.image ? (
                <div className="product-image-container">
                <img
                  src={product.image.filePath}
                  alt={product.image.fileName}
                />
                </div>
              ) : (
                <p>No image set for this product</p>
              )}
            </Card>
            
            <h2>{product.title}</h2>
            {product.isArchived ? (
              <h4><span className="--color-danger">Archived</span></h4>
            ) : (null)}
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(product.description),
              }}
            ></div>
            <h4>Urgency: <span style={{fontWeight:300}}>{urgencyStatus(product.urgency)}</span></h4>
            <h4>Type: <span style={{fontWeight:300}}>{goodStatus(product.isGood)}</span></h4>
            {product.isGood ? (
              <h4>Quantity: <span style={{fontWeight:300}}>{product.quantity}</span></h4>
            ) : (null)}
            <h4>Program: <span style={{fontWeight:300}}>{product.program}</span></h4>
            
            {product.isOngoing ? (
              <h4>Need By Date: <span style={{fontWeight:300}}>Ongoing</span></h4>
            ) : (
            <h4>Need By Date: <span style={{fontWeight:300}}>{moment(product.needByDate).format('MMM DD YYYY')} ({moment(product.needByDate).fromNow()})</span></h4>
            )}
            
            <h4>Claimed Status: {claimedStatus(product.isClaimed)}</h4>

          
           
            <h4>Organizer Contact Info: <p style={{whiteSpace:"pre-wrap", marginTop:10}}>{product.organizerInfo}</p></h4>
          
          <ClaimButton product={product}/>

            <hr />
            <code className="--color-dark">
              Created on: {moment(product.createdAt).format('MMM DD YYYY')} ({moment(product.createdAt).fromNow()})
            </code>
            <code className="--color-dark">
              Last Updated: {moment(product.createdAt).format('MMM DD YYYY')} ({moment(product.createdAt).fromNow()})
            </code>

            
            {admin ? (
<div className="admin-controls">
<Button component={Link} to={`/edit-product/${id}`} variant="contained" color="secondary" disableElevation className="button">
             <BiEdit/> <h4>Edit</h4>
            </Button>

          

<Button component={Link} to={`/add-donation/${id}`} variant="contained" color="secondary" disableElevation className="button">
              <BsGift/> <h4>Create Donation</h4>
            </Button>

            <Button onClick={() => confirmDelete(id)} variant="contained" color="secondary" disableElevation className="button">
             <BiTrash/> <h4>Delete</h4>
            </Button>
                          
              {product.isClaimed ? (null): (
                <Button component={Link} to={`/edit-product/${id}/claimed`} variant="contained" color="secondary" disableElevation className="button">
              <FaRegHandPaper/> <h4>Set Claimed</h4>
            </Button>
              )}

{product.isArchived ? (null): (
            <Button component={Link} to={`/edit-product/${id}/archive`} variant="contained" color="secondary" disableElevation className="button">
              <BiArchive/> <h4>Archive</h4>
            </Button>
              )}
            

            
  </div>
            ) : (null)}
           


          
          </div>
        )}
      </Card>
    </div>
  );
};

export default ProductDetail;
