import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FilterButton } from './ProductListFilter';
import {BiSort} from "react-icons/bi";


export function InlineSortButton({title,sortTitle1, sortTitle2, thisSort,thisSetter, otherSetters, descending, descendingSetter}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveSort = () => {
    thisSetter(false)
  };

  const handleClickSortOption = (isDescending) => {
    if (thisSort && descending && isDescending){
        thisSetter(false)
    } else {
        thisSetter(true)
        descendingSetter(isDescending)
    }
    for (var i = 0; i < otherSetters.length; i++) {
        // otherSetters[i](false)
    } 
  };


  const isSelected = (isDescending) => {
    if (thisSort && ((descending && isDescending) || (!descending && !isDescending))){
        return {backgroundColor: "var(--color-primary)",
        color: "#fff",
    "&:hover":{
        backgroundColor: "var(--color-primary)"
    }}
    }
   
  };

  
  return (
    <div>
      <Button
        id="filter-positioned-button"
        aria-controls={open ? 'filter-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
            padding:"0px",
            minWidth:"20px"
        }}
      >
        <BiSort size={25} color={"black"}/>
      </Button>
      <Menu
        id="filter-positioned-menu"
        aria-labelledby="filter-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        
         <MenuItem onClick={() => handleRemoveSort(false)} disabled={thisSort == false }>Remove Sort</MenuItem>
        
        <MenuItem onClick={() => handleClickSortOption(false)} sx={isSelected(false)}>Sort by {title} {sortTitle1}</MenuItem>
        <MenuItem onClick={() => handleClickSortOption(true)} sx={isSelected(true)}>Sort by {title} {sortTitle2}</MenuItem>
       
      </Menu>
    </div>
  );
}