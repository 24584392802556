import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import DonationForm from "../../components/donations/donationForm/DonationForm";
import {
  getDonation,
  getDonations,
  selectIsLoading,
  selectDonation,
  updateDonation,
} from "../../redux/features/donations/donationsSlice";

import { stringToBoolean } from "../../utils/helperFunctions";

const EditDonation = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);

  const donationEdit = useSelector(selectDonation);

  const [donation, setDonation] = useState(donationEdit);
  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState("");

  useEffect(() => {
    dispatch(getDonation(id));
  }, [dispatch, id]);

  useEffect(() => {
    setDonation(donationEdit);

    setDescription(
      donationEdit && donationEdit.donation_description ? donationEdit.donation_description : ""
    );
    
    setDate(
      donationEdit && donationEdit.donation_date ? new Date(donationEdit.donation_date) : new Date()
    );
  }, [donationEdit]);

 

  const saveDonation = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("donation_date", date);
    formData.append("donation_description", description);

    await dispatch(updateDonation({ id, formData }));
    await dispatch(getDonations());
    navigate("/donations");
  };

  return (
    <div>
      {isLoading && <Loader />}
      <h3 className="--mt">Edit Item</h3>
      <DonationForm
        product={{title:donation?.item_title,program:donation?.program,description:donation?.item_description}}
        description={description}
        setDescription={setDescription}
        date={date}
        setDate={setDate}
        saveDonation={saveDonation}
      />
    </div>
  );
};

export default EditDonation;
