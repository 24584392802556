import { createSlice } from "@reduxjs/toolkit";
import moment from 'moment';
import _ from 'lodash'

const initialState = {
  filteredDonations: [],
};

const filterSlice = createSlice({
  name: "donation_filter",
  initialState,
  reducers: {
    FILTER_DONATIONS(state, action) {
      const { donations, search, filters } = action.payload;
      var tempDonations = donations.filter(
        (donation) =>
          donation.item_title.toLowerCase().includes(search.toLowerCase()) ||
          donation.item_description.toLowerCase().includes(search.toLowerCase()) || 
          donation.program.toLowerCase().includes(search.toLowerCase()) ||
          donation.donated_description.toLowerCase().includes(search.toLowerCase())
      );

  

      if (filters.program.length > 0){
        tempDonations = tempDonations.filter(
          (donation) =>
            donation.program == filters.program
        );
      }



      state.filteredDonations = tempDonations;
    },
  },
});

export const { FILTER_DONATIONS } = filterSlice.actions;

export const selectFilteredDonations = (state) => state.donation_filter.filteredDonations;

export default filterSlice.reducer;
