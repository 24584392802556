import { createSlice } from "@reduxjs/toolkit";
import moment from 'moment';
import _ from 'lodash'

const initialState = {
  filteredProducts: [],
  filteredArchivedProducts: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    FILTER_PRODUCTS(state, action) {
      const { products, search, filters, sorts } = action.payload;
      var tempProducts = products.filter(
        (product) =>
          product.title.toLowerCase().includes(search.toLowerCase()) ||
          product.description.toLowerCase().includes(search.toLowerCase()) || product.program.toLowerCase().includes(search.toLowerCase())
      );

      if (filters.good) {
        tempProducts = tempProducts.filter(
          (product) =>
            product.isGood == true
        );
      }
      else if (filters.service) {
        tempProducts = tempProducts.filter(
          (product) =>
            product.isGood == false
        );
      }
      
      if (filters.claimed) {
        tempProducts = tempProducts.filter(
          (product) =>
            product.isClaimed == true
        );
      } else  if (filters.claimed) {
        tempProducts = tempProducts.filter(
          (product) =>
            product.isClaimed == false
        );
      }

      if (filters.program.length > 0){
        tempProducts = tempProducts.filter(
          (product) =>
            product.program == filters.program
        );
      }

      if (sorts.urgency) {
        if (sorts.urgencyOrder){
          tempProducts = _.orderBy(tempProducts, [(obj) => obj.urgency], ['asc'])
        } else{
          tempProducts = _.orderBy(tempProducts, [(obj) => obj.urgency], ['desc'])
          
        }
      }

      if (sorts.needBy) {
        // tempProducts.sort(function(a,b){
        //   // Turn your strings into dates, and then subtract them
        //   // to get a value that is either negative, positive, or zero.
        //   return new Date(b.needByDate) - new Date(a.needByDate);
        // });
        if (sorts.needByOrder){
          // console.log(tempProducts)
          tempProducts = _.orderBy(tempProducts, [(obj) => new Date(obj.needByDate)], ['desc'])
        } else{
          // tempProducts.sort((a, b) => (Date(a.needByDate) > Date(b.qneedByDate)) ? 1 : -1)
          tempProducts = _.orderBy(tempProducts, [(obj) => new Date(obj.needByDate)], ['asc'])
        }
      }

      state.filteredProducts = tempProducts;
    },
    FILTER_ARCHIVED_PRODUCTS(state, action) {
      const { products, search, filters, sorts } = action.payload;
      var tempProducts = products.filter(
        (product) =>
          product.title.toLowerCase().includes(search.toLowerCase()) ||
          product.description.toLowerCase().includes(search.toLowerCase()) || product.program.toLowerCase().includes(search.toLowerCase())
      );

      if (filters.good) {
        tempProducts = tempProducts.filter(
          (product) =>
            product.isGood == true
        );
      }
      else if (filters.service) {
        tempProducts = tempProducts.filter(
          (product) =>
            product.isGood == false
        );
      }
      
      if (filters.claimed) {
        tempProducts = tempProducts.filter(
          (product) =>
            product.isClaimed == true
        );
      } else  if (filters.claimed) {
        tempProducts = tempProducts.filter(
          (product) =>
            product.isClaimed == false
        );
      }

      if (filters.program.length > 0){
        tempProducts = tempProducts.filter(
          (product) =>
            product.program == filters.program
        );
      }

      if (sorts.urgency) {
        if (sorts.urgencyOrder){
          tempProducts = _.orderBy(tempProducts, [(obj) => obj.urgency], ['asc'])
        } else{
          tempProducts = _.orderBy(tempProducts, [(obj) => obj.urgency], ['desc'])
          
        }
      }

      if (sorts.needBy) {
        // tempProducts.sort(function(a,b){
        //   // Turn your strings into dates, and then subtract them
        //   // to get a value that is either negative, positive, or zero.
        //   return new Date(b.needByDate) - new Date(a.needByDate);
        // });
        if (sorts.needByOrder){
          // console.log(tempProducts)
          tempProducts = _.orderBy(tempProducts, [(obj) => new Date(obj.needByDate)], ['desc'])
        } else{
          // tempProducts.sort((a, b) => (Date(a.needByDate) > Date(b.qneedByDate)) ? 1 : -1)
          tempProducts = _.orderBy(tempProducts, [(obj) => new Date(obj.needByDate)], ['asc'])
        }
      }

      state.filteredArchivedProducts = tempProducts;
    },
  },
});

export const { FILTER_PRODUCTS } = filterSlice.actions;

export const selectFilteredPoducts = (state) => state.filter.filteredProducts;

export default filterSlice.reducer;
