import { FaTh, FaRegChartBar, FaCommentAlt,FaPlusSquare } from "react-icons/fa";
import { BiImageAdd } from "react-icons/bi";
import {BsFillPersonLinesFill, BsGiftFill, BsPlusLg, BsFillPersonPlusFill, BsQuestionCircleFill,BsArchiveFill} from "react-icons/bs"

const menu = [
  {
    title: "Dashboard",
    icon: <FaTh />,
    path: "/dashboard",
    adminReqiured:false,
  },
  {
    title: "Archive",
    icon: <BsArchiveFill />,
    path: "/archive",
    adminReqiured:true,
  },
  {
    title: "Donations",
    icon: <BsGiftFill />,
    path: "/donations",
    adminReqiured:true,
  },
  {
    title: "Add Item",
    icon: <BsPlusLg />,
    path: "/add-product",
    adminReqiured:true,
  },
  {
    title: "Register User",
    icon: <BsFillPersonPlusFill />,
    path: "/register",
    adminReqiured:true,
  },
  {
    title: "Account",
    icon: <BsFillPersonLinesFill />,
    path: "/profile",
    adminReqiured:false,
  },
  {
    title: "Get Help",
    icon: <BsQuestionCircleFill />,
    path: "/help",
    adminReqiured:false
  },
];

export default menu;
