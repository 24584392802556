import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FilterButton } from './ProductListFilter';
import {BsFilterLeft} from "react-icons/bs";


export function InlineFilterButton({filters}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAll = () => {
    for (var i = 0; i < filters.length; i++) {
        filters[i].setter(false)
    } 
  };

  const isAllSelected = () => {
    for (var i = 0; i < filters.length; i++) {
        if (filters[i].filterOn){
            return false
        }
    } 
    return true
  };

  const handleClickFilterOption = (filter) => {
    for (var i = 0; i < filters.length; i++) {
        filters[i].setter(false)
    } 
    filter.setter(!filter.filterOn)
  };


  
  return (
    <div>
      <Button
        id="filter-positioned-button"
        aria-controls={open ? 'filter-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
            padding:"0px",
            minWidth:"20px"
        }}
      >
        <BsFilterLeft size={25} color={"black"}/>
      </Button>
      <Menu
        id="filter-positioned-menu"
        aria-labelledby="filter-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem><FilterButton label={"All"} selected={filters.filter(item => item.filterOn) == 0} color="#1c1d1d" onClick={handleClickAll}/></MenuItem>
        {filters.map((filter, index) => {

        return <MenuItem><FilterButton label={filter.title} selected={filter.filterOn} color={filter.color} onClick={() => handleClickFilterOption(filter)}/></MenuItem>
        
            
            })}
      </Menu>
    </div>
  );
}



export function InlineProgramFilterButton({filter,setter}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (event) => {
        console.log(event.target.getAttribute('value'))
        setter(event.target.getAttribute('value'))
      };

      const isSelected = (program) => {
        if (filter == program){
            return {backgroundColor: "var(--color-primary)",
            color: "#fff",
        "&:hover":{
            backgroundColor: "var(--color-primary)"
        }}
        } 
      };

      const programList=[
        "Pathway of Hope",
        "Emergency Family Shelter",
        "Children’s Health Homes",
        "Employment Services",
        "Supervised Visitation",
        "Rapid Rehousing",
        "Emergency Services",
        "Youth Services"
      ]
    
    return (
      <div>
        <Button
          id="filter-positioned-button"
          aria-controls={open ? 'filter-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
              padding:"0px",
              minWidth:"20px"
          }}
        >
          <BsFilterLeft size={25} color={"black"}/>
        </Button>
        <Menu
          id="filter-positioned-menu"
          aria-labelledby="filter-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
            <MenuItem value={""} onClick={(e)=>setter("")} sx={isSelected("")}>All Programs</MenuItem>
            {programList.map((program, index) => {
                return <MenuItem value={program} onClick={handleChange} 
                sx={isSelected(program)}>{program}</MenuItem>
            })}
          
        </Menu>
      </div>
    );
  }