import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import DonationForm from "../../components/donations/donationForm/DonationForm";
import { selectName } from "../../redux/features/auth/authSlice";


import {
  getProduct,
  selectProduct,
  selectIsLoading,
} from "../../redux/features/product/productSlice";

import { stringToBoolean } from "../../utils/helperFunctions";

import {
  createDonation,
  
} from "../../redux/features/donations/donationsSlice";

const initialState = {
  title: "",
  program: "Other",
  quantity: 0,
  isGood: true,
  isClaimed:false,
  isOngoing:false,
  urgency:1
};

const AddDonation = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const name = useSelector(selectName);
  const incoming_product = useSelector(selectProduct);
  const [product, setProduct] = useState(incoming_product);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    dispatch(getProduct(id));
  }, [dispatch, id]);

  useEffect(() => {
    setProduct(incoming_product);
  }, [incoming_product]);

  


  const saveDonation = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user", name);
    formData.append("item_title", product._id);
    formData.append("item_title", product.title);
    formData.append("program", product.program);
    formData.append("item_description",product.description);
    formData.append("donation_description",description);
    formData.append("donation_date",date);
    formData.append("organizerInfo", product.organizerInfo);

    console.log(...formData)

    const success = await dispatch(createDonation(formData))
    if (success.type == "donations/create/fulfilled"){
    navigate(`/product-detail/${id}`);
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      <h3 className="--mt">Add New Donation</h3>
      <DonationForm
        product={product}
        description={description}
        setDescription={setDescription}
        date={date}
        setDate={setDate}
        
        saveDonation={saveDonation}
      />
    </div>
  );
};

export default AddDonation;
