import React, { useEffect, useState } from "react";
import { SpinnerImg } from "../../loader/Loader";
import "./productList.scss";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";

import Search from "../../search/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTER_PRODUCTS,
  selectFilteredPoducts,
} from "../../../redux/features/product/filterSlice";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  deleteProduct,
  getProducts,
} from "../../../redux/features/product/productSlice";
import { Link } from "react-router-dom";
import moment from 'moment';
import { Button,Select,MenuItem } from "@mui/material";
import ProductListFilter from "./ProductListFilter";
import {InlineFilterButton,InlineProgramFilterButton} from "./InlineFilterButton";
import { InlineSortButton } from "./InlineSortButton";


const ProductList = ({ products, isLoading, archive }) => {
  const [search, setSearch] = useState("");
  const [openFilter, openFilterScreen] = useState(false);
  const [filterGood, setFilterGood] = useState(false);
  const [filterService, setFilterService] = useState(false);
  const [filterClaimed, setFilterClaimed] = useState(false);
  const [filterUnclaimed, setFilterUnclaimed] = useState(false);
  const [filterProgram, setFilterProgram] = useState("");

  const [sortByNeededDate, setSortByNeededDate] = useState(false); // sort
  const [sortByUrgency, setSortByUrgency] = useState(false); // sort
  const [urgencyOrderDes, seturgencyOrderDes] = useState(true); // sort
  const [neededDateOrderDes, setNeededStateOrderDes] = useState(true); // sort

  const filteredProducts = useSelector(selectFilteredPoducts);

  const dispatch = useDispatch();

  const clearFilter = () => {
    setFilterGood(false);
    setFilterService(false);
    setFilterClaimed(false);
    setFilterUnclaimed(false);
    setFilterProgram("")
  };

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };


  //   Begin Pagination
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(filteredProducts.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredProducts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredProducts]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredProducts.length;
    setItemOffset(newOffset);
  };
  
  const goodFilter = {title:"Only Goods",color: "#000",filterOn:filterGood,setter:setFilterGood}
  const serviceFilter = {title:"Only Services",color: "#8a2be2",filterOn:filterService,setter:setFilterService}

  const claimedFilter = {title:"Only Claimed",color: "#008000",filterOn:filterClaimed,setter:setFilterClaimed}
  const unclaimedFilter = {title:"Only Unclaimed",color: "#ff0000",filterOn:filterUnclaimed,setter:setFilterUnclaimed}

  

  useEffect(() => {
    var filters={good:filterGood,service:filterService,claimed:filterClaimed,unclaimed:filterUnclaimed,program:filterProgram}
    var sorts = {needBy:sortByNeededDate,urgency:sortByUrgency,needByOrder:neededDateOrderDes,urgencyOrder:urgencyOrderDes}
    dispatch(FILTER_PRODUCTS({ products, search, filters, sorts }));
  }, [products, search, filterGood, filterService, filterClaimed, filterUnclaimed, filterProgram, sortByNeededDate, sortByUrgency, neededDateOrderDes, urgencyOrderDes, dispatch]);



  const urgencyStatus = (urgency) => {
    if (urgency == 3) {
      return <span className="--color-high">High</span>;
    } else if (urgency == 2) {
      return <span className="--color-medium">Medium</span>;
    } else {
      return <span className="--color-low">Low</span>;
    }
  };

  return (
    <div className="product-list">
      <hr />
      <div className="table">
        <div className="--flex-between --flex-dir-column">
          <span>
            {archive ? (
              <h3>Archived Items</h3>
            ) : (
              <h3>Active Items</h3>
            )}
            
          </span>

          
          
          <span className="--flex-right">
          {filterGood || filterService || filterClaimed || filterUnclaimed || filterProgram.length > 0 ? (
              <Button
              disableElevation
              variant="outlined"
              onClick={clearFilter}>
                Clear Filter
              </Button>
          ) : (null)} 
              <ProductListFilter open={openFilter} openFilterScreen={openFilterScreen} filters={{good:filterGood,service:filterService,claimed:filterClaimed,unclaimed:filterUnclaimed,program:filterProgram}} setters={{good:setFilterGood,service:setFilterService,claimed:setFilterClaimed,unclaimed:setFilterUnclaimed,program:setFilterProgram}}/>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </span>
        </div>

        {isLoading && <SpinnerImg />}

      
    
          

        <div className="table">
          {!isLoading && products.length === 0 ? (
            <p>-- No product found, please add a product...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th><div className="--flex-left">Type <InlineFilterButton filters={[goodFilter,serviceFilter]}/></div></th>
                  <th><div className="--flex-left">Program <InlineProgramFilterButton filter={filterProgram} setter={setFilterProgram}/></div></th>
                  <th><div className="--flex-left">Need By <InlineSortButton title={"Date"} thisSort={sortByNeededDate} thisSetter={setSortByNeededDate} otherSetters={[setSortByUrgency]} descending={neededDateOrderDes} descendingSetter={setNeededStateOrderDes} sortTitle1={"(Sooner to Later)"} sortTitle2={"(Later to Sooner)"}/></div></th>
                  <th><div className="--flex-left">Urgency <InlineSortButton title={"Urgency"} thisSort={sortByUrgency} thisSetter={setSortByUrgency} otherSetters={[setSortByNeededDate]} descending={urgencyOrderDes} descendingSetter={seturgencyOrderDes} sortTitle1={"(High to Low)"} sortTitle2={"(Low to High)"}/></div></th>
                  <th><div className="--flex-left">Claimed <InlineFilterButton filters={[claimedFilter,unclaimedFilter]}/></div></th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>

              <tbody>
                {currentItems.map((product, index) => {
                  const { _id, title, description, isGood, program, needByDate,isOngoing, urgency, isClaimed } = product;
                  return (
                    
                    // <tr key={_id}>
                      <Link className="table-row" to={`/product-detail/${_id}`}><td>{shortenText(title, 50)}</td>
                      {/* <Td to={`/product-detail/${_id}`}>{shortenText(title, 16)}</Td> */}
                      <td>{shortenText(extractContent(description), 60)}</td>
                      
                      <td>{isGood ? (<p className="tag good">Good</p>):(<p className="tag service">Service</p>)}</td>
                      <td>{program}</td>
                      {isOngoing ? (
                        <td>Ongoing</td>
                      ) : (
                        <td>{moment(needByDate).format('MMM DD YYYY')} ({moment(needByDate).fromNow()})</td>
                      )}
                      
                      <td>{urgencyStatus(urgency)}</td>
                      <td>{isClaimed ? (<p className="tag claimed">Claimed</p>):(<p className="tag unclaimed">Unclaimed</p>)}</td>
                   
                      </Link>
                    // </tr>
                    
                  );
                  
                })}
              </tbody>
            </table>
          )}
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Prev"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePage"
        />
      </div>
    </div>
  );
};

function extractContent(html) {
  return new DOMParser()
      .parseFromString(html, "text/html")
      .documentElement.textContent;
}


export default ProductList;
