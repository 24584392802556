import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { BACKEND_URL } from "../../../services/authService";
import { selectUser } from "../../../redux/features/auth/authSlice";
import { Button, CircularProgress } from "@mui/material";
import { FaRegHandPaper } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { SET_USER } from "../../../redux/features/auth/authSlice";
import { getUser } from "../../../services/authService";


const ClaimButton = ({product}) => {
    const dispatch = useDispatch();

    const [to, setTo] = useState("");
    const [didClaim, setDidClaim] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const productRef = useRef(product);

  const data = {
    to,
    subject,
    message,
  };

  useEffect(() => {
    productRef.current = product;
}, [product]);

  useEffect(() => {
    setIsLoading(true);
    async function getUserData() {
      const data = await getUser();
      setIsLoading(false);
      await dispatch(SET_USER(data)); 
      setTo(productRef.current.adminEmail)
      setSubject(`${data.name} would like to claim ${productRef.current.title}`);
      setMessage(`
        <h3>User</h3>
        <p>Name: ${data.name},</p>
        <p>Number: ${data.phone},</p>
        <p>Email: ${data.email}</p>
    
        <h3>Item</h3>
        <p>Title: ${productRef.current.title},</p>
        <p>Program: ${productRef.current.program},</p>
        <p>Description: ${productRef.current.description}</p>
  
        <a href='https://buffalo-salvation-army-portal.vercel.app/product-detail/${productRef.current._id}'>Go To Item</a>
      `);
  }  
    getUserData();
   
  }, [dispatch]);

  
  const claim = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BACKEND_URL}/api/contactus/claim`, data, { withCredentials: true });
      toast.success(response.data.message);
      setDidClaim(true)
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
    {isLoading ? (<CircularProgress/>) : (
        didClaim ? (<p>An email has been sent to the item admin to let them know you would like to claim this item.</p>): (
            <Button onClick={claim} variant="contained" color="primary" disableElevation>
            <h4>Claim</h4>
           </Button>
        )
        
    )}
    </div>
  );
};

export default ClaimButton;
