import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/features/auth/authSlice";
import productReducer from "../redux/features/product/productSlice";
import donationsReducer from "../redux/features/donations/donationsSlice";
import filterReducer from "../redux/features/product/filterSlice";
import donationFilterReducer from "../redux/features/donations/filterSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    product: productReducer,
    donations: donationsReducer,
    filter: filterReducer,
    donation_filter: donationFilterReducer
  },
});
