export default  {
    palette: {
    primary: {
      main: "#ed1c2a",
      light: "#F84956",
      dark: "#C10715"
    },
    secondary: {
        main: "#fff",
        light: "#ffffff80",
        dark: "#f4f6f6"
      },
      info: {
        main: "#000",
        light: "#1c1d1d80",
        dark: "#1c1d1d"
      },
    
  },
  components: {
    MuiButton: { 
      styleOverrides: { 
        root: { fontSize: "1.5em" } 
      } 
    },
    MuiTooltip: {
      tooltip: {
          fontSize: "2rem",
      },
  },
  },
  overrides: {
    MuiTooltip: {
        tooltip: {
            fontSize: "3em",
        },
    },
},

}