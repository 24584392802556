import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import donationsService from "./donationsService";
import { toast } from "react-toastify";
import moment from 'moment';

const initialState = {
  donation: null,
  donations: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ""
};

// Create New Donation
export const createDonation = createAsyncThunk(
  "donations/create",
  async (formData, thunkAPI) => {
    try {
      return await donationsService.createDonation(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all donations
export const getDonations = createAsyncThunk(
  "donations/getAll",
  async (_, thunkAPI) => {
    try {
      return await donationsService.getDonations();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete a Donation
export const deleteDonation = createAsyncThunk(
  "donations/delete",
  async (id, thunkAPI) => {
    try {
      return await donationsService.deleteDonation(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get a donation
export const getDonation = createAsyncThunk(
  "donations/getDonation",
  async (id, thunkAPI) => {
    try {
      return await donationsService.getDonation(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update donation
export const updateDonation = createAsyncThunk(
  "donations/updateDonation",
  async ({ id, formData }, thunkAPI) => {
    try {
      return await donationsService.updateDonation(id, formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const productSlice = createSlice({
  name: "donation",
  initialState,
  reducers: {
    CALC_WITHIN_7_DAYS(state, action) {
      const donations = action.payload;
      var present = moment();
      var within7Days = [];
      donations.map((item) => {
        const { needByDate } = item;
        if (moment(needByDate).diff(present, 'days') <= 7){
          return within7Days.push(item);
        }
      });
     state.within7Days = within7Days.length
     
    },
    CALC_CLAIMED(state, action) {
      const donations = action.payload;
      const claimed = [];
      donations.map((item) => {
        const { isClaimed } = item;
        if (isClaimed){
        return claimed.push(item);
        }
      });
      state.claimed = claimed.length;
    },
    CALC_UNCLAIMED(state, action) {
      const donations = action.payload;
      const unclaimed = [];
      donations.map((item) => {
        const { isClaimed } = item;
        if (!isClaimed){
        return unclaimed.push(item);
        }
      });
      state.unclaimed = unclaimed.length;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDonation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDonation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        console.log(action.payload);
        state.donations.push(action.payload);
        toast.success("Donation added successfully");
      })
      .addCase(createDonation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getDonations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDonations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        console.log(action.payload);
        state.donations = action.payload;
      })
      .addCase(getDonations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(deleteDonation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDonation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Donation deleted successfully");
      })
      .addCase(deleteDonation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getDonation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDonation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.donation = action.payload;
      })
      .addCase(getDonation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateDonation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDonation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Donation updated successfully");
      })
      .addCase(updateDonation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});


export const selectIsLoading = (state) => state.donations.isLoading;
export const selectDonation = (state) => state.donations.donation;

export default productSlice.reducer;
