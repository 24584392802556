import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `${BACKEND_URL}/api/donations/`;

// Create New Donation
const createDonation = async (formData) => {
  const response = await axios.post(API_URL, formData,{ withCredentials: true });
  return response.data;
};

// Get all donations
const getDonations = async () => {
  const response = await axios.get(API_URL);
  console.log(response)
  return response.data;
};

// Delete a Donation
const deleteDonation = async (id) => {
  const response = await axios.delete(API_URL + id);
  return response.data;
};
// Get a Donation
const getDonation = async (id) => {
  const response = await axios.get(API_URL + id);
  return response.data;
};
// Update Donation
const updateDonation = async (id, formData) => {
  const response = await axios.patch(`${API_URL}${id}`, formData);
  return response.data;
};

const donationService = {
  createDonation,
  getDonations,
  getDonation,
  deleteDonation,
  updateDonation,
};

export default donationService;
