import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Card from "../../card/Card";
import {TextField,Select,MenuItem,Radio,RadioGroup,FormControlLabel, Tooltip, IconButton} from '@mui/material'
import {BsInfoCircle} from 'react-icons/bs'
import { stringToBoolean } from "../../../utils/helperFunctions";
import "./ProductForm.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ProductForm = ({
  product,
  productImage,
  imagePreview,
  date,
  setDate,
  description,
  setDescription,
  handleInputChange,
  handleImageChange,
  saveProduct,
}) => {
  return (
    <div className="add-product">
      <Card cardClass={"card"}>
        <form onSubmit={saveProduct} className='product-form'>

          <Card cardClass={"group"}>
            <label>Image</label>
            <code className="--color-dark">
              Supported Formats: jpg, jpeg, png
            </code>
            <input
              type="file"
              name="image"
              onChange={(e) => handleImageChange(e)}
            />

            {imagePreview != null ? (
              <div className="image-preview">
                <img src={imagePreview} alt="product" />
              </div>
            ) : (
              <p>No image set for this poduct.</p>
            )}
          </Card>

          <label>Title:</label>
         
          <TextField
           type="text"
           placeholder="Title"
           name="title"
           value={product?.title}
           onChange={handleInputChange}
           />

           

  
        <ToolTipFormLabel labelText="Good or Service" toolTip="The Item Type. Either being a good (physical item) or service (action item)."/>
        <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="isGood"
        value={product.isGood}
        onChange={handleInputChange}
      >
        <FormControlLabel value={true} control={<Radio />} label="Good" />
        <FormControlLabel value={false} control={<Radio />} label="Service" />
      </RadioGroup>


        <ToolTipFormLabel labelText="Is Claimed:" toolTip="A checkbox whether the item(s) are currently accounted for by a member of the organization. This does not mean that the item has been cleared, as someone may still fall back on their claim."/>
        <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="isClaimed"
        value={product.isClaimed}
        onChange={handleInputChange}
      >
        <FormControlLabel value={true} control={<Radio />} label="Claimed" />
        <FormControlLabel value={false} control={<Radio />} label="Unclaimed" />
      </RadioGroup>

      <ToolTipFormLabel labelText="Is Archived:" toolTip="A checkbox whether the item(s) should be archived in the system. The archive is used to keep track of all previous items that are not currently Active."/>
        <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="isArchived"
        value={product.isArchived}
        onChange={handleInputChange}
      >
        <FormControlLabel value={false} control={<Radio />} label="Active" />
        <FormControlLabel value={true} control={<Radio />} label="Archive" />
        
      </RadioGroup>


      <ToolTipFormLabel labelText="Program:" toolTip="The program the item is for."/>
          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={product.program}
          name="program"
          label="Good or Service"
          onChange={handleInputChange}
        >
          <MenuItem value={"Pathway of Hope"}>Pathway of Hope</MenuItem>
          <MenuItem value={"Emergency Family Shelterway"}>Emergency Family Shelter</MenuItem>
          <MenuItem value={"Children’s Health Homes"}>Children’s Health Homes</MenuItem>
          <MenuItem value={"Employment Services"}>Employment Services</MenuItem>
          <MenuItem value={"Supervised Visitation"}>Supervised Visitation</MenuItem>
          <MenuItem value={"Rapid Rehousing"}>Rapid Rehousing</MenuItem>
          <MenuItem value={"Emergency Services"}>Emergency Services</MenuItem>
          <MenuItem value={"Youth Services"}>Youth Services</MenuItem>
          <MenuItem value={"Other"}>Other</MenuItem>
        </Select>

        <ToolTipFormLabel labelText="Need By Date:" toolTip="The date in which the item should be fulfilled. If the item is a Service, this would be the date by which the service will be fulfilled on. If multiple dates, this field would be for the soonest date and other dates can go in the description with more detail. If the item is to be fulfilled ASAP, leave this field as TODAY’S date. If the item is an Ongoing resource, put TODAY's date and check Ongoing below"/>
        <div style={{ border: "1px #c5c5c5 solid",borderRadius:"4px"}}>
        <DatePicker selected={date} onChange={(newDate) => setDate(newDate)} />
        </div>

        <ToolTipFormLabel labelText="Is Ongoing:" toolTip="A checkbox whether the item is an ongoing resource, meaning that it can always be donated. Example: diapers."/>
        <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="isOngoing"
        value={product.isOngoing}
        onChange={handleInputChange}
      >
        <FormControlLabel value={true} control={<Radio />} label="Is Ongoing" />
        <FormControlLabel value={false} control={<Radio />} label="Limited Quantity Needed" />
      </RadioGroup>

        <ToolTipFormLabel labelText="Urgency:" toolTip="A score of Low, Medium, or High to how urgent the item is."/>
        <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="urgency"
        value={product.urgency}
        onChange={handleInputChange}
      >
        <FormControlLabel value={3} control={<Radio />} label="High" />
        <FormControlLabel value={2} control={<Radio />} label="Medium" />
        <FormControlLabel value={1} control={<Radio />} label="Low" />
      </RadioGroup>


        
        {(stringToBoolean(product.isGood) && !stringToBoolean(product.isOngoing)) ? (
          <div>
          <ToolTipFormLabel labelText="Quantity:" toolTip="The amount needed for a particular (good) item."/>
          <TextField
          type="number"
          placeholder="Quantity"
          name="quantity"
          value={product?.quantity}
          onChange={handleInputChange}
          />
          </div>
        ):(null)}

          
        <ToolTipFormLabel labelText="Description:" toolTip="A detailed description for the item."/>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            modules={ProductForm.modules}
            formats={ProductForm.formats}
          />

<ToolTipFormLabel labelText="Organizer Contact Info:" toolTip="Please leave contact info (name,email or number) for the particular member(s) that are organizing this item."/>
         
         <TextField
          type="text"
          placeholder="Title"
          name="organizerInfo"
          value={product?.organizerInfo}
          onChange={handleInputChange}
          multiline
          fullWidth
          minRows={3}
          />

          <div className="--my">
            <button type="submit" className="--btn --btn-primary">
              Save Item
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

ProductForm.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ align: [] }],
    [{ color: ["#000000","blue","red","green"] }, { background: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["clean"],
  ],
};
ProductForm.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "color",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "video",
  "image",
  "code-block",
  "align",
];

function ToolTipFormLabel({ labelText, toolTip }) {
  return (
    <label>{labelText} <Tooltip title={<p>{toolTip}</p>}><IconButton><BsInfoCircle size={15} color="#000" /></IconButton></Tooltip></label>
  );
}

export default ProductForm;
