import React, { useEffect, useState } from "react";
import { SpinnerImg } from "../../loader/Loader";
import "./donationsList.scss";
import Search from "../../search/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTER_DONATIONS,
  selectFilteredDonations,
} from "../../../redux/features/donations/filterSlice";
import ReactPaginate from "react-paginate";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import moment from 'moment';
import { Button } from "@mui/material";
import DonationListFilter from "./DonationListFilter";
import {InlineFilterButton,InlineProgramFilterButton} from "./InlineFilterButton";
import { InlineSortButton } from "./InlineSortButton";


const DonationList = ({ donations, isLoading }) => {
  const [search, setSearch] = useState("");
  const [openFilter, openFilterScreen] = useState(false);
  const [filterProgram, setFilterProgram] = useState("");

  const filteredDonations = useSelector(selectFilteredDonations);

  const dispatch = useDispatch();

  const clearFilter = () => {
    setFilterProgram("")
  };

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };


  //   Begin Pagination
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(filteredDonations.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredDonations.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredDonations]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredDonations.length;
    setItemOffset(newOffset);
  };
  

  useEffect(() => {
    var filters={program:filterProgram}
    dispatch(FILTER_DONATIONS({ donations, search, filters }));
  }, [donations, search, filterProgram, dispatch]);



  return (
    <div className="donation-list">
      <hr />
      <div className="table">
        <div className="--flex-between --flex-dir-column">
          <span>
            <h3>Donated Items</h3>
          </span>

          
          
          <span className="--flex-right">
          {filterProgram.length > 0 ? (
              <Button
              disableElevation
              variant="outlined"
              onClick={clearFilter}>
                Clear Filter
              </Button>
          ) : (null)} 
              <DonationListFilter open={openFilter} openFilterScreen={openFilterScreen} filters={{program:filterProgram}} setters={{program:setFilterProgram}}/>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </span>
        </div>

        {isLoading && <SpinnerImg />}

      
    
          

        <div className="table">
          {!isLoading && donations.length === 0 ? (
            <p>-- No donation found, please add a donation...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Item Title</th>
                  <th>Donation Details</th>
                  <th><div className="--flex-left">Program <InlineProgramFilterButton filter={filterProgram} setter={setFilterProgram}/></div></th>
                  
                  <th>Donation Date</th>
                </tr>
              </thead>

              <tbody>
                {currentItems.map((donation, index) => {
                  const { _id, item_title, item_description, program, donation_description, donation_date } = donation;
                  return (
                    
                      <Link className="table-row" to={`/donation-detail/${_id}`}><td>{shortenText(item_title, 50)}</td>
                      <td>{shortenText(extractContent(donation_description), 60)}</td>
                      <td>{program}</td>
          
                      <td>{moment(donation_date).format('MMM DD YYYY')} ({moment(donation_date).fromNow()})</td>
                      </Link>
                    
                    
                  );
                  
                })}
              </tbody>
            </table>
          )}
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Prev"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePage"
        />
      </div>
    </div>
  );
};

function extractContent(html) {
  return new DOMParser()
      .parseFromString(html, "text/html")
      .documentElement.textContent;
}


export default DonationList;
