import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAdmin, selectName, SET_LOGIN } from "../../redux/features/auth/authSlice";
import { logoutUser } from "../../services/authService";



const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const name = useSelector(selectName);
  const admin = useSelector(selectAdmin);

  const logout = async () => {
    await logoutUser();
    await dispatch(SET_LOGIN(false));
    navigate("/login");
  };

  return (
    <div className="--pad header">
      <div className="--flex-between-end" style={{    paddingBottom: "10px"}}>
        <h3>
          <span className="--fw-thin">Welcome, </span>
          <span className="--color-primary">{name}</span>
          {admin ? (
            <span className="--color-secondary" style={{fontSize:"16px"}}> ADMIN</span>
          ):(null)}
        </h3>
        <Button 
            disableElevation
            variant="contained"
            color="primary"
            onClick={logout}>
              Logout
            </Button>
            
      </div>
      <hr />
    </div>
  );
};

export default Header;
