import React, { useEffect } from "react";
import "./ProductSummary.scss";
import { BsBag,BsPersonCheck, BsCalendarEvent,BsPersonX } from "react-icons/bs";
import InfoBox from "../../infoBox/InfoBox";
import { useDispatch, useSelector } from "react-redux";
import {
  CALC_UNCLAIMED,
  CALC_CLAIMED,
  CALC_WITHIN_7_DAYS,
  selectUnclaimedItems,
  selectClaimedItems,
  selectWithin7Days,
} from "../../../redux/features/product/productSlice";

// Icons
const calendarIcon = <BsCalendarEvent size={40} color="#000" />;
const totalIcon = <BsBag size={40} color="#000" />;
const unclaimedIcon = <BsPersonX size={40} color="#000" />;
const claimedIcon = <BsPersonCheck size={40} color="#000" />;

// Format Amount
export const formatNumbers = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const ProductSummary = ({ products }) => {
  const dispatch = useDispatch();
  const within7Days = useSelector(selectWithin7Days);
  const claimedItems = useSelector(selectClaimedItems);
  const unclaimedItems = useSelector(selectUnclaimedItems);

  useEffect(() => {
    dispatch(CALC_WITHIN_7_DAYS(products));
    dispatch(CALC_CLAIMED(products));
    dispatch(CALC_UNCLAIMED(products));
  }, [dispatch, products]);

  return (
    <div className="product-summary">
      <h3 className="--mt">Stats</h3>
      <div className="info-summary">
        <InfoBox
          icon={totalIcon}
          title={"Total Items"}
          count={products.length}
          bgColor="card1"
        />
        <InfoBox
          icon={calendarIcon}
          title={"Within 7 Days"}
          count={within7Days}
          bgColor="card2"
        />
        <InfoBox
          icon={claimedIcon}
          title={"Claimed Items"}
          count={claimedItems}
          bgColor="card3"
        />
        <InfoBox
          icon={unclaimedIcon}
          title={"Unclaimed Items"}
          count={unclaimedItems}
          bgColor="card4"
        />
      </div>
    </div>
  );
};

export default ProductSummary;
