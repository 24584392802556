import * as React from 'react';
import {Box,Button,Modal,Select,MenuItem} from '@mui/material';
import {BsFilter} from "react-icons/bs";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display:"flex",
  flexDirection:"column",
    alignItems:"center",
    gap:"50px"
};

export default function ProductListFilter({open,openFilterScreen,filters,setters}) {

    const handleClickAllGoodsService = () => {
        setters.good(false)
        setters.service(false)
    }

    const handleClickGoods = () => {
        setters.good(true)
        setters.service(false)
    }

    const handleClickServices = () => {
        setters.good(false)
        setters.service(true)
    }

    const handleClickAllClaimed = () => {
        setters.claimed(false)
        setters.unclaimed(false)
    }

    const handleClickClaimed = () => {
        setters.claimed(true)
        setters.unclaimed(false)
    }

    const handleClickUnclaimed = () => {
        setters.claimed(false)
        setters.unclaimed(true)
    }

  return (
    <div>
      <Button
            color="info"
            variant="outlined"
            sx={{textTransform:"none"}}
            onClick={() => openFilterScreen(true)}>
              <BsFilter size={35} color="#000" style={{paddingRight:"10px"}}/>
              Filter
            </Button>
      <Modal
        open={open}
        onClose={() => openFilterScreen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        
<div className='--flex-dir-column --dir-column --align-center --gap20'>
        <h3>Filter Options</h3>
        <h4>Good/Service</h4>
        <div className='--flex-center'>
        <FilterButton label={"All"} selected={!filters.good && !filters.service} color="#1c1d1d" onClick={handleClickAllGoodsService}/>
        <FilterButton label={"Only Goods"} selected={filters.good} color="#000" onClick={handleClickGoods}/>
        <FilterButton label={"Only Services"} selected={filters.service} color="#8a2be2" onClick={handleClickServices}/>
        </div>
        </div>

        <div className='--flex-dir-column --dir-column --align-center --gap20'>
        <h4>Claimed/Unclaimed</h4>
        <div className='--flex-center'>
        <FilterButton label={"All"} selected={!filters.claimed && !filters.unclaimed} color="#1c1d1d" onClick={handleClickAllClaimed}/>
        <FilterButton label={"Only Claimed"} selected={filters.claimed} color="#008000" onClick={handleClickClaimed}/>
        <FilterButton label={"Only Unclaimed"} selected={filters.unclaimed} color="#ff0000" onClick={handleClickUnclaimed}/>
        </div>
        </div>

        <div className='--flex-dir-column --dir-column --align-center --gap20'>
        <h4>Program</h4>
           <Select
        labelId="simple-select-label"
        id="simple-select"
        value={filters.program}
        displayEmpty
        defaultValue=""
        name="program"
        label="Program"
        fullWidth
        onChange={(e)=>setters.program(e.target.value)}
      >
        <MenuItem value={""}>All Programs</MenuItem>
        <MenuItem value={"Pathway of Hope"}>Pathway of Hope</MenuItem>
        <MenuItem value={"Emergency Family Shelterway"}>Emergency Family Shelter</MenuItem>
        <MenuItem value={"Children’s Health Homes"}>Children’s Health Homes</MenuItem>
        <MenuItem value={"Employment Services"}>Employment Services</MenuItem>
        <MenuItem value={"Supervised Visitation"}>Supervised Visitation</MenuItem>
        <MenuItem value={"Rapid Rehousing"}>Rapid Rehousing</MenuItem>
        <MenuItem value={"Emergency Services"}>Emergency Services</MenuItem>
        <MenuItem value={"Youth Services"}>Youth Services</MenuItem>
        <MenuItem value={"Other"}>Other</MenuItem>
      </Select> 
      </div>

      <Button
      variant='contained'
      color='secondary'
      fullWidth
      onClick={() => openFilterScreen(false)}>
        Done
      </Button>
        </Box>
      </Modal>
    </div>
  );
}

export function FilterButton({ label, selected, color,onClick }) {
    return (
      <Button 
      color="secondary"
      onClick={onClick}
      style={{
        backgroundColor: selected ? `${color}` : "#f4f6f6",
        
        margin: "0px",
    borderRadius: "15px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
    }}>
        <p style={{
            lineHeight: "1px",
            padding:"10px",
            color: selected ? "#fff" : "#000",
            fontWeight: 600,
            textAlign: "center",

        }}>
            {label}
        </p>
      </Button>
    );
  }