import React from "react";
import { Link } from "react-router-dom";
import "./Home.scss";
import logo from '../../assets/logo.png'
import hero from '../../assets/salvationarmydashboardimage.jpeg'
import { ShowOnLogin, ShowOnLogout } from "../../components/protect/HiddenLink";
import {Button} from '@mui/material'
const Home = () => {
  return (
    <div className="home">
      <nav className="container --flex-between ">
        <div className="app-logo">
          <img src={logo}/>
        </div>

        <ul className="home-links">
          <ShowOnLogout>
            <li>
            <Button 
            disableElevation
            variant="contained"
            color="primary"
            component={Link}
            to="/login">
              Login
            </Button>
            </li>
          </ShowOnLogout>
          <ShowOnLogin>
          <li>
            <Button 
            disableElevation
            variant="contained"
            color="primary"
            component={Link}
            to="/dashboard">
              Dashboard
            </Button>
            </li>
          </ShowOnLogin>
        </ul>
      </nav>
      {/* HERO SECTION */}
      <section className="container hero">
        <div className="hero-text">
          <h1>The Salvation Army of Buffalo Community Portal</h1>
          <p>
          Welcome to The Salvation Army of Buffalo Community Portal, your one-stop source for information on the goods and services provided to those in need. Here, you can easily track and manage the items and services that have been fulfilled, as well as identify areas where additional support is needed. Whether you are a member of The Salvation Army team, or someone in need of assistance, this portal is designed to make it simple and convenient to access the resources you need.
          </p>
          <div className="hero-buttons">
          <Button 
            disableElevation
            variant="outlined"
            color="primary"
            href="https://easternusa.salvationarmy.org/empire/buffalo/"
            target="_blank">
              Buffalo Salvation Army Website
            </Button>
          </div>
        </div>

        <div className="hero-image">
          <img src={hero} alt="Inventory" />
        </div>
      </section>
    </div>
  );
};


export default Home;
